<template>
  <CCard>
    <CCardHeader>
      <strong>{{ title }}</strong>
    </CCardHeader>
    <CCardBody>
      <CForm>
        <CInput
          id="challenge-title"
          description="Título del reto"
          label="Título del reto"
          horizontal
        />
        <CTextarea
          id="challenge-description"
          description="Descripción"
          label="Descripción"
          horizontal
          enabled
        />

        <CRow class="form-input-row">
          <CCol lg="3" md="3">Tipo de reto</CCol>
          <CCol lg="9" md="9" class="select-row">
            <select id="challenge-type">
              <option selected="selected" value="select-challenge">Elige tipo de reto</option>
              <option
                v-for="(challenge, index) in challengeTypes"
                :key="index"
                :value="challenge.challenge_type_id"
                >{{ challenge.challenge_type_name }}</option>
            </select>
          </CCol>
        </CRow>
      </CForm>
      <div class="d-flex justify-content-center">
        <CButton
          class="btn btn-secondary"
          style="margin-right: 1em"
          @click="back()"
        >{{backButton}}</CButton>
        <CButton class="btn btn-success" @click="editButton()">{{ textButton }}</CButton>
      </div>
      <CRow class="justify-content-center">
        <CCol md="9" class="p-4">
          <CAlert color="danger" dismissible :show.sync="contAlertParams" close-button>
            <strong>AVISO</strong>
            {{errorText}}
            <CProgress
              :max="10"
              :value="contAlertParams"
              height="3px"
              color="danger"
              animated
            />
          </CAlert>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import axios from "axios";
import "core-js/stable";
import "regenerator-runtime/runtime";
import config from "@/config";
import {getChallengesDataTypes, checkUser} from '../../utils/utils';
function getDataReadyToCreate() {
  let challengeTitle = document.getElementById("challenge-title").value;
  let challengeDescription = document.getElementById("challenge-description").value;
  let challengeType = document.getElementById("challenge-type").value;
  let new_challenge = {
    challenge_title: challengeTitle,
    challenge_description: challengeDescription,
    challenge_type: challengeType,
  };
  return new_challenge;
}
export default {
  name: "EditChallenge",
  data() {
    return {
      id: null,
      challengeTypes: [],
      title: "Nuevo Reto",
      textButton: "Crear",
      backButton: "Volver",
      contAlertParams: 0,
      errorText: "Campos vacios o erroneos"
    };
  },
  created() {
    if(!checkUser()) return;
    this.getChallengesTypes();
  },
  methods: {
    getChallengesTypes() {
      let data = getChallengesDataTypes();
      data.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        this.challengeTypes = result.challenge_types;
      });
      return this.challengeTypes;
    },
    /**
     * Funcion encargada de crear o modificar la reto
     */
    editButton() {
  
      let challengeTitle = document.getElementById("challenge-title").value;
      let challengeDescription = document.getElementById("challenge-description").value;
      let challengeType = document.getElementById("challenge-type").value;

      if (challengeTitle == ""){
        this.errorText="El campo Título del reto esta vacio";
        this.showFailureParameters();
        return;
      }
      if (challengeDescription===""){
        this.errorText="El campo Descripción esta vacio";
        this.showFailureParameters();
        return;
      }
      if (challengeType=="select-challenge"){
        this.errorText="Seleccione un tipo de reto";
        this.showFailureParameters();
        return;
      }
      if (challengeTitle.length>100){
        this.errorText = "El nombre es demasiado largo.";
        this.showFailureParameters();
        correct = false;
      }
      if (challengeDescription.length>400){
        this.errorText = "La descripción es demasiado larga.";
        this.showFailureParameters();
        correct = false;
      }

      if (!this.id) {
        let token = localStorage.token;
        axios
          .post(config.BACK_IP + config.BACK_IP_RETOS, getDataReadyToCreate(), {
            headers: {
              token: token
            }
          })
          .then(() => {
            this.$router.push("/challenge");
          })
          .catch(error => {
            if (error.response) {
              if (error.response.status == 400) {
                this.errorText=error.response.data.message;
                this.showFailureParameters();
              }
              if (error.response.status == 401) {
                window.location = "#/pages/login";
              }
              if (error.response.status == 404) {
                window.location = "#/pages/404";
              }
              if (error.response.status == 409) {
                this.errorText = 'Conflicto con duplicidad';
                this.showFailureParameters();
              }
              if (error.response.status == 500) {
                window.location = "#/500";
              }
            }
          });
        return;
      }
    },
    back() {
      window.history.back();
    },
    showFailureParameters() {
      this.contAlertParams = 10;
    }
  }
};
</script>
<style>
textarea.form-control{
  height: 200px;
}
</style>